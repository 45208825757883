<template>
  <div class="container">
    <div class="header">
      <div class="text">{{header[activeIndex]}}</div>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link ellipsis">
          {{ title }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="index"
            v-for="(item, index) in list"
            :key="item"
            >{{ item }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="components">
      <component1 v-if="activeIndex === 0" />
      <component2 v-if="activeIndex === 1" />
      <component3 v-if="activeIndex === 2" />
      <component4 v-if="activeIndex === 3" />
    </div>
  </div>
</template>

<script>
import component1 from "../components/overView/component1.vue";
import component2 from "../components/overView/component2.vue";
import component3 from "../components/overView/component3.vue";
import component4 from "../components/overView/component4.vue";
export default {
  components: { component1, component2, component3, component4 },
  data() {
    return {
      activeIndex: 0,
      title: "中亮新能源分布式",
      list: [
        "中亮新能源分布式",
        "中亮新能源分布式2",
        "中亮新能源分布式3",
        "中亮新能源分布式4",
      ],
      header:[
        "高压全额上网-彩钢瓦",
        "高压自发自用-水泥顶",
        "低压自发自用-彩钢瓦",
        "低压-水泥顶"
      ]
    };
  },
  methods: {
    handleCommand(command) {
      this.activeIndex = command;
      this.title = this.list[this.activeIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dropdown {
  color: #1997f8;
  font-size: 18px;
  border: 1px solid #1997f8;
  padding: 10px;
  border-radius: 02px;
  height: 50px;
}

::v-deep .el-icon--right {
  margin-left: 10px
}

.ellipsis {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container {
  width: 100%;
  height: calc(100vh - 85px);
  overflow: hidden;
  background-image: url('../../../assets/images/Monitor/bg.png'); /* 替换为您的图片路径 */
    background-size: cover; /* 背景图片覆盖整个元素 */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 背景图片不重复 */
  .header {
    display: flex;
    padding: 10px;
    height: 100px;
    justify-content: space-between;
    .text {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.components {
  width: 1400px;
  margin: auto;
}
</style>
