<template>
  <div class="relative">
    <div class="fixed">
      <!-- 光伏实时功率 -->
      <div class="gfbox">
        <img
          class="img1"
          src="../../../../assets/images/Monitor/com1-zuchuan.png"
        />
        <!-- 缺少的竖线 -->
        <div class="sxian"></div>
        <!-- 光伏实际功率 -->
        <div class="box gf" style="width: 100px">
          <div class="text">光伏实际功率</div>
          <div class="box-content">
            <span class="color">29.786</span> <span class="color2">w</span>
          </div>
        </div>
        <div class="btn img4">组件</div>
        <!-- <img class="img4" src="../../../../assets/images/Monitor/u4.png" /> -->
      </div>

      <!-- 逆变器 -->
      <div class="nbbox">
        <!-- <img
          class="nbimg10"
          src="../../../../assets/images/Monitor/com1-nbx.png"
          alt=""
        /> -->
        <!-- 逆变器状态 -->
        <div class="box nb" style="width: 150px">
          <div class="text">逆变器状态</div>
          <div class="box-content">
            <div class="flex2">
              <span>正常运行</span>
              <span>0台</span>
            </div>
            <div class="flex2">
              <span class="red">告警运行</span>
              <span>0台</span>
            </div>
            <div class="flex2">
              <span>限电运行</span>
              <span>0台</span>
            </div>
            <div class="flex2">
              <span>正常运行</span>
              <span>29台</span>
            </div>
            <div class="flex2">
              <span class="red">故障停机</span>
              <span>2台</span>
            </div>
            <div class="flex2">
              <span>通讯中断</span>
              <span>2台</span>
            </div>
            <div class="flex2">
              <span class="red">含为零支路</span>
              <span>0台</span>
            </div>
          </div>
        </div>

        <!-- 缺少的竖线 -->
        <div class="sx2"></div>
        <div class="btn2 img5">逆变器</div>
        <!-- <img class="img5" src="../../../../assets/images/Monitor/u52.png" /> -->
      </div>

      <!-- 电网关口 -->
      <div class="dwbox">
        <img
          class="img2"
          src="../../../../assets/images/Monitor/com1-8.png"
          height="120px"
        />
        <!-- <img class="img6" src="../../../../assets/images/Monitor/u73.png" /> -->
        <div class="btn img6">电网</div>
        <div class="dwxian"></div>
        <div class="box dw" style="width:150px">
          <div class="text">电网关口</div>
          <div class="box-content">
            <span class="color">29.786</span> <span class="color2">kw</span>
          </div>
        </div>
      </div>

      <!-- 负载 -->
      <div class="xbbox">
        <img
          class="img3"
          src="../../../../assets/images/Monitor/com1-nbx.png"
        />

        <div class="btn img7">负载</div>

        <!-- <img class="img7" src="../../../../assets/images/Monitor/u78.png" /> -->
        <div class="xian"></div>
        <div class="box fz" style="width: 150px">
          <div class="text">负载</div>
          <div class="box-content">
            <div class="flex2">
              <span>P(MW)</span>
              <span>0.00</span>
            </div>
            <div class="flex2">
              <span>Q(MVar)</span>
              <span>0.00</span>
            </div>
            <div class="flex2">
              <span>Cos</span>
              <span>0.00</span>
            </div>
            <div class="flex2">
              <span>Uab(kV)</span>
              <span>0.00</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 并网柜 -->
      <div class="bwbox">
        <img
          class="img10"
          src="../../../../assets/images/Monitor/u66.png"
          alt=""
        />
        <div class="btn img9">升压电站</div>
        <div class="bwxian"></div>
        <!-- <img class="img9" src="../../../../assets/images/Monitor/u93.png" /> -->
        <div class="box bw" style="width: 150px">
          <div class="text">升压电站状态</div>
          <div class="box-content">
            <div class="flex2" v-for="item in syList" :key="item.name">
              <span>{{ item.name }}</span>
              <span>{{ item.key }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="box sy" style="width: 2.5rem">
          <div class="text">升压电站状态</div>
          <div class="box-content">
            <div class="flex2" v-for="item in syList" :key="item.name">
              <span>{{ item.name }}</span>
              <span>{{ item.key }}</span>
            </div>
          </div>
        </div> -->
      </div>

      <img class="img8" src="../../../../assets/images/Monitor/u3.gif" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      syList: [
        { name: "电流", key: "100A" },
        { name: "电压", key: "100V" },
        { name: "负载", key: "100W" },
        { name: "预警", key: "10个" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  margin-top:3%;
  width: 1400px;
  height: 600px;
  position: relative;
  margin-left: 20px;
  // background-color: red;
  .box {
    border: 1px solid #90a0a0;
    padding: 10px;
    background-color: rgba(31, 61, 87, 0.5);
    border-radius: 5px;
    .text {
      color: #90a0a0;
      border-bottom: 2px solid #90a0a0;
      width: 100%;
      text-align: center;
      font-size: 16px;
      padding-bottom: 10px;
    }

    .box-content {
      text-align: center;
      padding: 10px 0 0 0;
      .color {
        color: #00ffff;
        font-size: 20px;
        font-weight: 700;
      }
      .color2 {
        color: #90a0a0;
      }
    }
  }

  .img8 {
    height: 850px;
    position: absolute;
    left: -200px;
    top: -90px;
  }

  .gfbox {
    position: relative;
    .img1 {
      width: 270px;
      position: absolute;
      left: -50px;
      top: 70px;
    }

    .img4 {
      position: absolute;
      left: 150px;
      top: 260px;
    }

    .sxian {
      width: 2px;
      height: 120px;
      position: absolute;
      left: 220px;
      top: 150px;
      background-color: #79acbd;
    }

    .gf {
      position: absolute;
      left: 150px;
      top: 55px;
    }
  }

  .nbbox {
    position: absolute;
    left: 597px;
    height: 450px;

    .nbimg10 {
      position: absolute;
      left: 150px;
      top: 110px;
    }
    .sx2 {
      width: 2px;
      height: 50px;
      position: absolute;
      top: 210px;
      left: 170px;
      background-color: #79acbd;
    }

    .img5 {
      position: absolute;
      left: 110px;
      top: 260px;
    }

    .nb {
      position: absolute;
      top: -30px;
      left: 80px;
    }
    // top: -100px;
  }

  .dwbox {
    position: absolute;
    top: 330px;
    left: -70px;
    .img2 {
      position: absolute;
    }
    .img6 {
      position: absolute;
      left: 90px;
      top: 50px;
    }

    .dwxian {
      position: absolute;
      left: 140px;
      top: 90px;
      width: 2px;
      height: 120px;
      background-color: #79acbd;
      z-index: 222;
    }

    .dw {
      position: absolute;
      top: 210px;
    }
  }

  .xbbox {
    position: absolute;
    left: 1000px;
    top: 350px;
    .img7 {
      position: absolute;
      left: 130px;
      top: 30px;
    }

    .fz {
      position: absolute;
      top: 130px;
      left: 50px;
    }
    .xian {
      width: 2px;
      height: 60px;
      background-color: #79acbd;
      position: absolute;
      left: 170px;
      top: 70px;
    }
    .img3 {
      position: absolute;
      left: -120px;
      top: 80px;
      width: 150px;
    }
  }

  .bwbox {
    position: absolute;
    left: 1200px;
    .img10 {
      position: absolute;
      left: -160px;
      top: 100px;
      width: 150px;
    }

    .bw {
      position: absolute;
      top: 50px;
    }
    .img9 {
      position: absolute;
      top: 260px;
      left: 65px;
    }

    .bwxian {
      width: 2px;
      height: 40px;
      background-color: #b7e9f3;
      position: absolute;
      left: 120px;
      top: 220px;
    }
    .sy {
      position: absolute;
      top: -130px;
    }
  }
}

.flex2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #85a1b9;
}
.red {  
  color: red;
}

.btn {
  width: 140px;
  height: 40px;
  border-radius: 10px;
  // text-align: center;
  padding-left: 50px;
  box-sizing: border-box;
  line-height: 40px;
  background-color: #153341;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;

  &::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #06c657;
    line-height: 20px;
    position: absolute;
    top: 8px;
    left: 20px;
  }
}

.btn2 {
  width: 140px;
  height: 40px;
  border-radius: 10px;
  // text-align: center;
  padding-left: 60px;
  box-sizing: border-box;
  line-height: 40px;
  background-color: #153341;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;

  &::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    line-height: 20px;
    position: absolute;
    top: 8px;
    left: 20px;
  }
}
</style>
